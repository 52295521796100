import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Button, IconSpinner, Modal } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  authenticationInProgress,
  login,
  selectIsAuthenticated,
  signup,
} from '../../ducks/auth.duck';
import Cookies from 'js-cookie';
import ListingClaimForm from './ListingClaimForm/ListingClaimForm';
import { claimInfoListing, claimListingSelectors } from '../../ducks/global.duck';
import { isArrayLength, showToaster } from '../../util/genericHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';

import css from './ListingClaimModal.module.css';
import LoginForm from '../../containers/AuthenticationPage/LoginForm/LoginForm';
import { pickUserFieldsData } from '../../util/userHelpers';
import {
  getNonUserFieldParams,
  SocialLoginButtonsMaybe,
} from '../../containers/AuthenticationPage/AuthenticationPage';
import SignupForm from '../../containers/AuthenticationPage/SignupForm/SignupForm';
import TermsAndConditions from '../../containers/AuthenticationPage/TermsAndConditions/TermsAndConditions';
import { useConfiguration } from '../../context/configurationContext';
import { parse } from '../../util/urlHelpers';

const getAuthInfoFromCookies = () => {
  return Cookies.get('st-authinfo')
    ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
    : null;
};

const ListingClaimModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    location,
    onManageDisableScrolling,
    saveEmailError,
    listingCardImages,
    currentListing,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const pathParams = useParams();
  const search = parse(location.search);

  const [authType, setAuthType] = useState('signup');

  const signupOrLoginInProgress = useSelector(authenticationInProgress);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const authInProgress = useSelector(authenticationInProgress);

  const { claimListingInProgress, claimListingSuccess, claimListingError } = useSelector(
    claimListingSelectors
  );

  const classes = classNames(rootClassName || css.root, className);

  const onClaimListing = async () => {
    const listingImagesMaybe =
      isArrayLength(listingCardImages) && listingCardImages.map(l => l?.url);
    try {
      await dispatch(
        claimInfoListing({ listingId: currentListing?.id?.uuid, listingImages: listingImagesMaybe })
      );
      onCloseModal();
      claimListingSuccess && showToaster('success', 'You have successfully claimed this listing');
      history.push(createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}, {}));
    } catch (error) {
      claimListingError &&
        showToaster(
          'error',
          'Something went wrong while claiming this listing. Please contact support.'
        );
    }
  };

  const handleSubmitSignup = async values => {
    const { userType, email, password, fname, lname, displayName, ...rest } = values;
    const displayNameMaybe = displayName ? { displayName: displayName.trim() } : {};

    const params = {
      email,
      password,
      firstName: fname.trim(),
      lastName: lname.trim(),
      ...displayNameMaybe,
      publicData: {
        userType,
        ...pickUserFieldsData(rest, 'public', userType, userFields),
      },
      privateData: {
        ...pickUserFieldsData(rest, 'private', userType, userFields),
      },
      protectedData: {
        ...pickUserFieldsData(rest, 'protected', userType, userFields),
        ...getNonUserFieldParams(rest, userFields),
      },
    };

    await dispatch(signup(params));
    onClaimListing();
  };

  const [authInfo, setAuthInfo] = useState(getAuthInfoFromCookies());

  const userTypeInPushState = location.state?.userType || null;
  const userType = pathParams?.userType || userTypeInPushState || null;

  const isClaimValidLink = search?.claim;
  console.log(isClaimValidLink);
  const config = useConfiguration();
  const { userFields, userTypes = [] } = config.user;
  const preselectedUserType = userTypes.find(conf => conf.userType === userType)?.userType || null;

  const locationFrom = location.state?.from || null;
  const authinfoFrom = authInfo?.from || null;
  const from = locationFrom || authinfoFrom || null;
  const fromMaybe = from ? { from } : null;

  const userTypeMaybe = preselectedUserType ? { userType: preselectedUserType } : null;

  useEffect(() => {
    if (isAuthenticated && isClaimValidLink && !claimListingInProgress) {
      onClaimListing();
    }
  }, [isAuthenticated, isClaimValidLink]);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen || isClaimValidLink}
      onClose={() => {
        onCloseModal();
        history.push(createResourceLocatorString('ListingPage', routeConfiguration(), pathParams, {}));
      }}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={intl.formatMessage({ id: 'ListingClaimModal.later' })}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="ListingClaimModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ListingClaimModal.description" />
      </p>
      {signupOrLoginInProgress || claimListingInProgress ? (
        <div className={css.claimInProgress}>
          <IconSpinner />
          <h4>
            <FormattedMessage id="ListingClaimModal.claimInProgress" />
          </h4>
        </div>
      ) : isAuthenticated ? (
        <div className={css.claimListingButton}>
          <Button type="button" onClick={onClaimListing}>
            <FormattedMessage id="ListingClaimModal.title" />
          </Button>
        </div>
      ) : authType === 'login' ? (
        <LoginForm
          className={css.loginForm}
          onSubmit={async ({ email, password }) => {
            await dispatch(login(email, password));
            await onClaimListing();
          }}
          inProgress={authInProgress}
        />
      ) : (
        <>
          <SocialLoginButtonsMaybe
            isLogin={false}
            showFacebookLogin={!!process.env.REACT_APP_FACEBOOK_APP_ID}
            showGoogleLogin={!!process.env.REACT_APP_GOOGLE_CLIENT_ID}
            from={`${location?.pathname}?claim=true`}
            {...userTypeMaybe}
          />
          <SignupForm
            className={css.signupForm}
            onSubmit={handleSubmitSignup}
            inProgress={authInProgress}
            termsAndConditions={
              <TermsAndConditions
                onOpenTermsOfService={() =>
                  typeof window !== 'undefined' &&
                  window.open(
                    createResourceLocatorString('TermsOfServicePage', routeConfiguration(), {}, {}),
                    '_blank'
                  )
                }
                onOpenPrivacyPolicy={() =>
                  typeof window !== 'undefined' &&
                  window.open(
                    createResourceLocatorString('PrivacyPolicyPage', routeConfiguration(), {}, {}),
                    '_blank'
                  )
                }
                intl={intl}
              />
            }
            preselectedUserType={preselectedUserType}
            setAuthType={setAuthType}
            isListingClaimModal
            userTypes={userTypes}
            userFields={userFields}
          />
        </>
      )}
    </Modal>
  );
};

const { string } = PropTypes;

ListingClaimModal.defaultProps = {
  className: null,
  rootClassName: null,
};

ListingClaimModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(ListingClaimModal);
