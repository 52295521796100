import React from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Button, FieldTextInput, Form, LocationAutocompleteInput } from '../../components';
import { compose } from 'redux';
import { withViewport } from '../../util/uiHelpers';

import css from './LandingSearchForm.module.css';

const identity = v => v;

const LocationSearchField = props => {
  const { intl, handleChange, isLandingLocation, isSearchPageFilter, isLandingPage } = props;
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          handleChange(value);
        };

        const searchInput = { ...restInput, onChange: searchOnChange };
        return (
          <LocationAutocompleteInput
            placeholder={intl.formatMessage({ id: 'LandingSearchForm.location' })}
            iconClassName={css.searchInputIcon}
            inputClassName={css.searchInput}
            predictionsClassName={css.searchPredictions}
            input={searchInput}
            meta={meta}
            rootClassName={isLandingLocation && css.landingLocationSearch}
            isLandingLocation={isLandingLocation}
            isSearchPageFilter={isSearchPageFilter}
          />
        );
      }}
    />
  );
};

const LandingSearchFormComponent = props => {
  const handleChange = location => {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      props.onSubmit({ location });
    }
  };

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          values,
          intl,
          form,
          bannerImage,
          isSearchPageFilter,
          isLandingPage,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className, {
          [css.searchPageFilter]: isSearchPageFilter,
        });

        return (
          <Form className={classes} onSubmit={e => e.preventDefault()}>
            <LocationSearchField
              intl={intl}
              handleChange={location => {
                if (location.selectedPlace) {
                  form.change('location', location);
                }
              }}
              isLandingLocation={true}
              isSearchPageFilter={isSearchPageFilter}
            />
            <FieldTextInput
              className={css.heroSearchFld}
              type="text"
              id="keywords"
              name="keywords"
              placeholder={intl.formatMessage({ id: 'LandingSearchForm.keywords' })}
            />
            <div className={classNames(isLandingPage ? css.fullWidthBtn : css.footerBtn)}>
              <Button
                className={classNames({
                  [css.searchIcon]: isSearchPageFilter,
                  [css.searchBtn]: !isSearchPageFilter,
                })}
                onClick={() => props.onSubmit(values)}
                type="submit"
              >
                {intl.formatMessage({ id: 'LandingSearchForm.search' })}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

LandingSearchFormComponent.defaultProps = { rootClassName: null, className: null };

LandingSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LandingSearchForm = compose(withViewport, injectIntl)(LandingSearchFormComponent);

export default LandingSearchForm;
